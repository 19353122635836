const LANGUAGES = {
    bg: 'bg',
    en: 'en'
};

const RESOLUTIONS = {
    xs: 500,
    sm: 768,
    md: 980,
};

const CLIENTS = {
    1: {
        bg: 'Сдружение Топливо',
        en: 'Toplivo Association'
    },
    2: {
        bg: 'ТОКО Кафе',
        en: 'TOKO Coffee'
    },
    3: {
        bg: 'Equilibrium',
        en: 'Equilibrium'
    },
    4: {
        bg: 'Vital Concept',
        en: 'Vital Concept'
    },
    5: {
        bg: 'Българска Асоциация по Таекуон-до',
        en: 'Bulgarian Association of Taekwon-Do'
    },
    6: {
        bg: 'Анелия и Иван Кацарови',
        en: 'Aneliya & Ivan Kacarovi'
    },
    7: {
        bg: 'Enoteca',
        en: 'Enoteca'
    },
    8: {
        bg: 'Omnia Sourcing',
        en: 'Omnia Sourcing'
    },
    9: {
        bg: 'GLOBUS',
        en: 'GLOBUS'
    },
    10: {
        bg: 'Orlen Oils',
        en: 'Orlen Oils'
    },
    11: {
        bg: 'Maxxmart',
        en: 'Maxxmart'
    },
    12: {
        bg: 'Walltopia',
        en: 'Walltopia'
    }
}

const HEADER = {

    name: {
        bg: 'Лора Милчева',
        en: 'Lora Milcheva'
    },
    phone: {
        bg: '0887 718 788',
        en: '+359 887 718 788'
    },
    email: 'lora.milcheva@outlook.com'
}

const FOOTER = {
    bg: 'Всички права запазени.',
    en: 'All rights reserved.'
};

const BUTTONS = {
    more: {
        bg: 'More',
        en: 'Повече'
    },
    contacts: {
        bg: 'Контакти',
        en: 'Contacts'
    },
    view: {
        bg: 'Виж',
        en: 'View'
    }
};

const ERROR_PAGE = {
    headline: {
        bg: 'Страницата не е намерена!',
        en: 'Page not found!'
    },
    text: {
        bg: '',
        en: ''
    },
    buttonText: {
        bg: 'Към проекти',
        en: 'Projects'
    }
}


export {
    LANGUAGES,
    RESOLUTIONS,
    FOOTER,
    BUTTONS,
    CLIENTS,
    HEADER,
    ERROR_PAGE
};