// Constants




function generateUrl(folderName, imageName) {
	return '/images/projects/' + folderName + '/' + imageName;
}

export default {
	generateUrl
};